/** @jsx jsx */
import { jsx } from '@emotion/core'
import Helmet from '../components/HelmetHelper'
import Container from '../components/Container'
import Text from '../components/content/Text'
import { Row, Cell } from '../components/Grid'
import { media, THEME_WHITE, THEME_PEACH } from '../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../components/Slide'
import { provideAccentTheme } from '../context/AccentThemeContext'

const NotFoundPage = () => {
	return (
		<div>
			<Helmet>
				<title>404</title>
				<meta
					name="description"
					content="Free Association is a design agency focused on digital products and brand systems. We’ve built longstanding partnerships with companies like Google, Toyota, eBay, PayPal, Waze, Equinox and a range of new ventures."
				/>
			</Helmet>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}
			>
				<Container>
					<Row>
						<Cell
							cols={{
								[media.small]: 12,
								[media.mediumUp]: 10,
							}}
							offset={{
								[media.small]: 0,
								[media.mediumUp]: 1,
							}}
						>
							<Text>
								<h1 className="--headline">404</h1>
							</Text>
						</Cell>
					</Row>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(NotFoundPage, THEME_PEACH)
